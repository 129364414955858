var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-spin',{attrs:{"spinning":_vm.spinShow}},[_c('a-form',{attrs:{"layout":"vertical","form":_vm.form}},[_c('a-form-item',{attrs:{"label":"设置角色名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name',
                    {rules:[
                      {type:'string',required:true,message:'角色名称不能为空'},
                      {type:'string',max:30,message:'角色名称不能超过30个字符'},
                      ],trigger:'change'}
                    ]),expression:"['name',\n                    {rules:[\n                      {type:'string',required:true,message:'角色名称不能为空'},\n                      {type:'string',max:30,message:'角色名称不能超过30个字符'},\n                      ],trigger:'change'}\n                    ]"}],attrs:{"placeholder":"输入角色名称"}})],1),_c('a-form-item',{attrs:{"required":"","label":"设置角色标示"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['role',
                          {rules:[
                            {type:'string',validator:_vm.validate},
                            ],trigger:'change',validateTrigger:'blur'}
                          ]),expression:"['role',\n                          {rules:[\n                            {type:'string',validator:validate},\n                            ],trigger:'change',validateTrigger:'blur'}\n                          ]"}],attrs:{"placeholder":"输入角色标示"}})],1),_c('a-form-item',{attrs:{"label":"角色类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'roleType',{rules: [{ required: true, message: '请选择角色类型'}],initialValue: _vm.formItem.roleType}]),expression:"[ 'roleType',{rules: [{ required: true, message: '请选择角色类型'}],initialValue: formItem.roleType}]"}],attrs:{"placeholder":"请选择角色类型"},on:{"change":_vm.roleTypeHandle}},_vm._l((_vm.roleType),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value,"label":item.label}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1),(_vm.formItem.roleType !== 'func')?_c('a-form-item',{key:"dataAuthTypeKey",attrs:{"label":"授权类型"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['dataAuthType',{initialValue: _vm.formItem.dataAuthType, rules:[{type:'string',required:true,message:'类型为必填项'}],trigger:'change'}]),expression:"['dataAuthType',{initialValue: formItem.dataAuthType, rules:[{type:'string',required:true,message:'类型为必填项'}],trigger:'change'}]"}],attrs:{"options":_vm.roleDataType}})],1):_vm._e(),_c('a-form-item',{attrs:{"label":"设置角色描述"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['description']),expression:"['description']"}],attrs:{"autosize":{ minRows: 2, maxRows: 6 },"placeholder":"输入角色描述"}})],1),_c('a-form-item',{attrs:{"label":"设置角色状态"}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:(['status', {
                          rules:[{type:'boolean',required:true}],
                          trigger:'change',valuePropName:'checked',initialValue:true}]),expression:"['status', {\n                          rules:[{type:'boolean',required:true}],\n                          trigger:'change',valuePropName:'checked',initialValue:true}]"}],attrs:{"checkedChildren":"启用","unCheckedChildren":"禁用"}})],1),(_vm.type === 'edit')?_c('a-form-item',{attrs:{"label":"最近更新时间"}},[_vm._v(" "+_vm._s(_vm.formItem.lastUpdated)+" ")]):_vm._e(),(_vm.type === 'edit')?_c('a-form-item',{attrs:{"label":"角色创建时间"}},[_vm._v(" "+_vm._s(_vm.formItem.dateCreated)+" ")]):_vm._e(),(_vm.type === 'edit')?_c('a-form-item',{attrs:{"label":"角色创建人（用户Username）"}},[_vm._v(" "+_vm._s(_vm.formItem.creater)+" ")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }